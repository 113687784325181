<template>
  <base-section id="team">
    <base-section-heading title="Über Mich" />
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row class="d-flex justify-center">
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="3"
            md="3"
          >
            <base-team-card
              align="center"
              light
              :frame="true"
              v-bind="card"
            />
          </v-col>
          <v-col
            v-for="info in infos"
            :key="info.title"
            cols="12"
            md="4"
          >
            <base-info-card v-bind="info" />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Mehr über mich
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:&quot;Arial&quot;,sans-sans-serif;">
                  <span style="font-size:13px;line-height:107%;font-family:&quot;PTSans&quot;,sans-serif;color:#404040;">Hast Du auch 100 und mehr offene Fragen an das Leben?</span>
                </p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:&quot;Arial&quot;,sans-sans-serif;">
                  <span style="font-size:13px;line-height:107%;font-family:&quot;PTSans&quot;,sans-serif;color:#404040;">Als Kind habe ich wohl &bdquo;komische&ldquo; Fragen gestellt. Ich habe oft Kopfsch&uuml;tteln geerntet und oft die Frage &bdquo;woher hast Du das nur?&ldquo; geh&ouml;rt. Ich wurde wohl deshalb die &bdquo;kleine Philosophin&ldquo; genannt. Ich war ein vertr&auml;umtes Kind mit viel Lust und Mut f&uuml;r Abenteuer. An Zust&auml;nde der Angst kann ich mich kaum erinnern und Neugierde haben mein Leben gepr&auml;gt. Auch war und bin ich noch immer wissensbegierig und auf der Suche nach Antworten.</span>
                </p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:&quot;Arial&quot;,sans-sans-serif;">
                  <span style="font-size:13px;line-height:107%;font-family:&quot;PTSans&quot;,sans-serif;color:#404040;">Als junge Erwachsene &uuml;berkamen mich, in der Nacht, alptraumartige Erlebnisse, die mich wirklich sehr &auml;ngstigten. Beim Parapsychologischen Verein fand ich, nach langem Suchen, erste Antworten. Dort absolvierte ich eine mediale Ausbildung und mit dem neuen Umfeld er&ouml;ffnete sich mir eine neue Welt. So erlebte ich, dass es noch andere Realit&auml;ten gibt, als nur die, die ich kannte.</span>
                </p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:&quot;Arial&quot;,sans-sans-serif;">
                  <span style="font-size:13px;line-height:107%;font-family:&quot;PTSans&quot;,sans-serif;color:#404040;">&bdquo;Was steckt hinter dem Offensichtlichen?&ldquo; &nbsp;Diese Frage begleitet mich nun st&auml;ndig und schenkt mir Orientierungspunkte. Durch viele Aus- und Weiterbildungen, vor allem aber durch die Lehrmeisterin &bdquo;Leben&ldquo; wuchs mein Bewusstsein st&auml;ndig weiter. Sowohl eine Nahtod-Erfahrung als auch eine Spontanheilung (Lungentumor) ver&auml;nderten meine Sicht auf das Leben und den Tod.</span>
                </p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:&quot;Arial&quot;,sans-sans-serif;">
                  <span style="font-size:13px;line-height:107%;font-family:&quot;PTSans&quot;,sans-serif;color:#404040;">Meine erste astrologische Beratung bekam ich geschenkt und ich &ouml;ffnete mich, trotz sehr grosser Skepsis, f&uuml;r diese erste Sitzung. Ich war so erstaunt, was diese Beraterin alles &uuml;ber mich auszusagen wusste, ohne dass sie mich je gesehen hatte. Schon nach der 1. Sitzung war mir vieles klarer und ich hatte viele &bdquo;Aha-Erlebnisse&ldquo;<br>&nbsp;Da wollte ich wissen, wie dies m&ouml;glich war, deshalb schrieb ich mich f&uuml;r den n&auml;chsten Ausbildungskurs in Astrologischer Psychologie ein. Das ist nun bald 30 Jahre her. Nach der spannenden und intensiven 3-j&auml;hrigen, ganzheitlichen Ausbildung verstand ich die Zusammenh&auml;nge der Natur, der Menschen, der Zyklen und nat&uuml;rlich mich und meine Mitmenschen um einiges besser.</span>
                </p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:&quot;Arial&quot;,sans-sans-serif;">
                  <span style="font-size:13px;line-height:107%;font-family:&quot;PTSans&quot;,sans-serif;color:#404040;">Mit Freude gebe ich mein breites Wissen in Beratungen und Ausbildungen weiter. <br>&nbsp;Ich sehe es als meine Aufgabe, die Menschen dort abzuholen, wo sie stehen und aufzuzeigen, welches einzigartige Potential sie in sich tragen und wie sie dieses umsetzen k&ouml;nnen. Wenn wir verstehen, woher wir kommen und wohin wir gehen, kommen wir immer mehr in die eigene Kraft und Klarheit.&nbsp;</span>
                </p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:&quot;Arial&quot;,sans-sans-serif;">
                  <span style="font-size:13px;line-height:107%;font-family:&quot;PTSans&quot;,sans-serif;color:#404040;">Hypnose ist eine sehr erfolgreiche und effiziente Methode zur L&ouml;sung von vielen Anliegen und Problemen. Ich arbeite gerne mit der aufdeckenden Hypnose, um an die Ursache eines Problems zu gelangen und es an der Wurzel zu l&ouml;sen.<br>&nbsp;Wie der Mensch etwas wahrgenommen hat ist der Schl&uuml;ssel zur L&ouml;sung, sei es bei Schmerz, Angst, Traurigkeit, Wut, Schuld, Scham, Dem&uuml;tigung, geringes Selbstwertgef&uuml;hl etc.<br>&nbsp;In Hypnose ist es m&ouml;glich, die eigenen Ressourcen und Selbstheilungskr&auml;fte zu aktivieren und L&ouml;sungen zu finden &ndash; alle Antworten sind bereits in ihrem Inneren vorhanden &ndash; ich unterst&uuml;tze sie dabei diese zu finden, um das Anliegen dauerhaft aufzul&ouml;sen&nbsp;</span>
                </p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:&quot;Arial&quot;,sans-sans-serif;text-align:justify;">
                  <span style="font-size:13px;line-height:107%;font-family:&quot;PTSans&quot;,sans-serif;color:#404040;">Je nach Thema kann es auch sinnvoll sein, auf einer &uuml;berpers&ouml;nlichen Ebene zu arbeiten. F&uuml;r mich ist es eine Selbstverst&auml;ndlichkeit, ganzheitlich zu arbeiten.&nbsp;</span>
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Steckbrief
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <h2 style="margin-top:15.0pt;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;line-height:107%;font-size:17px;font-family:&quot;Calibri Light&quot;,sans-serif;color:#2F5496;font-weight:normal;background:white;">
                  <strong><span style="font-size:19px;line-height:107%;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Monica Bachmann</span></span></strong>
                </h2>
                <ul
                  style="margin-bottom:0cm;"
                  type="disc"
                >
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Verheiratet seit 1986, 2 Kinder: 1987, 1989</span></span>
                  </li>
                </ul>
                <h3 style="margin-right:0cm;margin-left:0cm;font-size:18px;font-family:&quot;Times New Roman&quot;,serif;margin-top:15.0pt;margin-bottom:7.5pt;background:white;">
                  <span style="font-size:16px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;font-weight:normal;">Heutige T&auml;tigkeit&nbsp;</span>
                </h3>
                <ul
                  style="margin-bottom:0cm;"
                  type="disc"
                >
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">eigene Beratungspraxis f&uuml;r ganzheitliche Methoden:</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Psychologische Astrologie</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Hypnose und R&uuml;ckf&uuml;hrungen</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Maltherapie und Farbdialog</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">4 Dimensionen Methode</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Leitungsmitglied IPEI</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Lehrt&auml;tigkeit am IPEI (Kurse und Seminare in Astrologie &amp; Psychologie)</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung und Kurse in astrologischer Psychologie</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Buchhaltung und Finanzen am IPEI</span></span>
                  </li>
                </ul>
                <h3 style="margin-right:0cm;margin-left:0cm;font-size:18px;font-family:&quot;Times New Roman&quot;,serif;margin-top:15.0pt;margin-bottom:7.5pt;background:white;">
                  <span style="font-size:16px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;font-weight:normal;">Fr&uuml;here T&auml;tigkeiten</span>
                </h3>
                <ul
                  style="margin-bottom:0cm;"
                  type="disc"
                >
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">mehrj&auml;hrige F&uuml;hrung der eigenen Firma zusammen mit Ehemann</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">mehrj&auml;hrige T&auml;tigkeit als Personalverantwortliche</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">div. Personal- und Managementkurse</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Betriebswirtschaftliche Weiterbildungen</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">div. Auslands- und Sprachaufenthalte</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">kaufm. Ausbildung in &ouml;ffentlicher Verwaltung&nbsp;</span></span>
                  </li>
                </ul>
                <h3 style="margin-right:0cm;margin-left:0cm;font-size:18px;font-family:&quot;Times New Roman&quot;,serif;margin-top:15.0pt;margin-bottom:7.5pt;background:white;">
                  <span style="font-size:16px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;font-weight:normal;">Zusatzausbildungen&nbsp;</span>
                </h3>
                <ul
                  style="margin-bottom:0cm;"
                  type="disc"
                >
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Matrixhealing</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung zur dipl. Hypnotherapeutin NGH</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung in 4 Dimensionen-Methode</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung in Reinkarnationstherapie</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung zur Farbdialog- und Maltherapeutin IPEI</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Lehrerin f&uuml;r astrologische Psychologie am IPEI</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung zur astrologisch psychologischen Beraterin dipl. API</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">langj&auml;hrige Erfahrung in Meditation</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung in Traumdeutung bei A. Magnin</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung in Reiki bei E. Zehnder</span></span>
                  </li>
                  <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:&quot;Arial&quot;,sans-serif;color:black;background:white;">
                    <span style="font-size:13px;font-family:&quot;PT Sans&quot;,sans-serif;color:#404040;"><span style="color:windowtext;">Ausbildung in Medialit&auml;t bei M. Dietrich</span></span>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionTeam',

    data: () => ({
      panel: 1,
      cards: [
        {
          icon: 'MonicaBachmann.jpg',
        },
      ],
      infos: [
        {
          title: 'Monica Bachmann',
          subtitle: 'Es gibt für alles seine Zeit.',
          text: 'Als Astrologie- und Hypnose-Coach betrachte ich es als meine Aufgabe, den Menschen die Freude an sich selbst zurückzugeben. Sich ihrer Einzigartigkeit, ihren Potentialen und Möglichkeiten bewusst zu werden und diese sinnvoll zu nutzen.',
          callout: '02',
        },
      ],
    }),
  }
</script>
<style scoped>
.panel {
  height: 25px !important;
  width: 100% !important;
}
</style>
